import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0,3,4];

export const dictionary = {
		"/(app)": [~6,[2]],
		"/(auth)/login": [21,[5]],
		"/(app)/organizations/new": [~19,[2]],
		"/(app)/profile": [~20,[2]],
		"/(auth)/signup": [22,[5]],
		"/(app)/[org]": [~7,[2,3]],
		"/(app)/[org]/jobs": [~8,[2,3]],
		"/(app)/[org]/jobs/new": [18,[2,3]],
		"/(app)/[org]/jobs/[job=uuid]": [~9,[2,3,4]],
		"/(app)/[org]/jobs/[job=uuid]/candidates": [~10,[2,3,4]],
		"/(app)/[org]/jobs/[job=uuid]/candidates/new": [12,[2,3,4]],
		"/(app)/[org]/jobs/[job=uuid]/candidates/[candidate=uuid]": [~11,[2,3,4]],
		"/(app)/[org]/jobs/[job=uuid]/description": [~13,[2,3,4]],
		"/(app)/[org]/jobs/[job=uuid]/qualifications": [~14,[2,3,4]],
		"/(app)/[org]/jobs/[job=uuid]/qualifications/new": [~16,[2,3,4]],
		"/(app)/[org]/jobs/[job=uuid]/qualifications/[qualification=uuid]": [~15,[2,3,4]],
		"/(app)/[org]/jobs/[job=uuid]/settings": [17,[2,3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';